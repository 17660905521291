<template>
    <div class="content content--no-sub">
        <div class="main-title">
            <div class="main-title__left">
                <div class="h1">
                    Результаты поиска <span v-if="search.query.length > 0">«<a @click="openSearch()" href="javascript:void(0);">{{ search.query }}</a>»</span>
                </div>
            </div>

            <div class="main-title__right">
            </div>
        </div>
        <div class="container container__search">

            <div class="nothing-found" v-if="search.isEmpty && !search.isLoadSearch">
                <div class="df mt32">
                    <img src="@/assets/img/nothing.svg" alt="">
                    <div class="text-bold text-uppercase text-size-ml ml16">
                        ничего не найдено
                    </div>
                </div>
                <button @click="openSearch()" type="button" name="button" class="btn mt32">
                    изменить запрос
                </button>
                <hr>
            </div>
            <div class="baza-list" v-else-if="search.isLoadSearch">
                <div class="baza-item --ghost">
                    <div class="baza-item__header">
                        <div class="l">
                            <span></span>
                        </div>
                        <div class="r">
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div class="baza-item__info">
                        <div class="top">
                            <div class="l">
                                <div class="top__item date-block">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div class="top__item date-block">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div class="top__item date-block">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                            <div class="r">
                                <span></span>
                            </div>
                        </div>
                        <div class="bottom">
                            <div class="bottom__item customer">
                                <div class="l">
                                    <span></span>
                                </div>
                                <div class="r">
                                    <span></span>
                                </div>
                            </div>
                            <div class="bottom__item addr">
                                <div class="l">
                                    <span></span>
                                </div>
                                <div class="r">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-for="section in search.sections">
                    <div class="text-size-ml text-bold text-uppercase mt32">
                        {{ section.parent.name }}<span class="ml4 mr4">/</span><span v-if="user.groupCode == 'F'">{{ section.name }}</span><router-link v-else :to="section.link">{{ section.name }}</router-link>
                    </div>
                    <div class="baza-list pb0">
                        <baza-item v-for="request in section.items" :item="request" :key="request.id"/>
                    </div>

                </div>
                <div class="text-size-ml text-bold text-uppercase mt48" v-if="search.sectionsForbidden.length > 0">
                    найдено в недоступных разделах:
                </div>
                <div class="inaccessible-sections" v-if="search.sectionsForbidden">
                    <div class="item df" v-for="sectionForbidden in search.sectionsForbidden">
                        <span class="i i-lock3 mr8"></span>
                        {{ sectionForbidden.section }} / {{ sectionForbidden.status }} / Объект «{{ sectionForbidden.name }}»
                    </div>
                </div>
            </div>

        </div>
        <request-popups></request-popups>
    </div>
</template>
<script>
    import {mapState} from 'vuex';
    import Popup from '@/components/modal/Popup.vue';
    import BazaItem from '@/components/BazaItem.vue'
    import RequestPopups from '@/components/RequestPopups.vue';
    export default {
        name: "Search",
        props: {
            status: {
            }
        },
        components: {
            Popup,
            BazaItem,
            RequestPopups
        },
        data: function(){
          return {

          }
        },
        mounted() {
            this.$store.commit('requests/setSearchEmpty', true);
            this.$store.commit('requests/setIsSearchPage', true);
            if(this.search.query.trim().length > 0){
                this.$store.dispatch('requests/getListSearch');
            }
        },
        beforeUnmount() {
            this.$store.commit('requests/setIsSearchPage', false);
            this.$store.commit('requests/isLoadNextPage', true);
            this.$store.commit('requests/setLoad', false);
            this.$store.commit('requests/clearList');
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                search: state => state.requests.search,
                user: state => state.auth.user,
            }),
        },
        methods: {
            fetchData () {
                //console.log('fetch');
            },
            openSearch(){
                document.getElementById('searchFieldBtn').click();
            }
        },
        watch: {
            '$route': 'fetchData'
        },
    }
</script>
