<template>
    <div class="content" :class="{'content--no-sub':user.groupCode == 'F'}">
        <div class="main-title">
            <div class="main-title__left">
                <div class="h1" v-html="pageTitle"></div>
            </div>

            <div class="main-title__right">
                <span class="i i-info mr4"></span>
                <span class="t" v-html="pageDesc"></span>
                <a v-if="pageCode == 'repair_request'" href="javascript:void(0);" class="link-icon ml32 df" @click="openHowPressBtn">
					<span class="i i-question mr4"></span>
                    Какую кнопку нажать?
				</a>
            </div>
        </div>
        <!-- Блок Одобренные заявки - это уже -->
        <route-block :fromName="lastTabText.title" :toLink="lastTabText.link" :toName="lastTabText.toName" v-if="typeof(lastTabText) !== 'undefined'"/>

        <!-- Здесь пока ничего нет -->
        <nothing-block v-if="(itemSecondActive.name || activeTopMenu.name && user.groupCode == 'F') && !requests.length && !isLoad && typeof(lastTabText) === 'undefined'"/>

        <section class="container">
            <requests-list v-if="pageCode && typeof(lastTabText) === 'undefined'" :status="pageCode"/>
        </section>

        <request-popups></request-popups>

    </div>
</template>
<script>
    import {mapState} from 'vuex';
    import Tooltip from '@/components/Tooltip.vue';
    import Popup from '@/components/modal/Popup.vue';
    import RequestsNew from '@/components/requests/RequestsNew.vue'
    import RequestsWaitContract from '@/components/requests/RequestsWaitContract.vue'
    import RequestsVerify from '@/components/requests/RequestsVerify.vue'
    import RequestsPreApproved from '@/components/requests/RequestsPreApproved.vue'
    import RequestsWaitingInterview from '@/components/requests/RequestsWaitingInterview.vue'
    import RequestsRejected from '@/components/requests/RequestsRejected.vue'
    import RequestsApproved from '@/components/requests/RequestsApproved.vue'
    import RequestsList from "@/components/requests/RequestsList";
    import RouteBlock from '@/components/RouteBlock.vue';
    import NothingBlock from '@/components/NothingBlock.vue';
    import RequestPopups from '@/components/RequestPopups.vue';
	import { isMobile } from 'mobile-device-detect';

    export default {
        name: "Requests",
        props: {

        },
        components: {
            RequestsList,
            Tooltip,
            Popup,
            RequestsNew,
            RequestsWaitContract,
            RequestsVerify,
            RequestsPreApproved,
            RequestsWaitingInterview,
            RequestsRejected,
            RequestsApproved,
            RouteBlock,
            NothingBlock,
            RequestPopups,
        },
        data: function(){
          return {

          }
        },
        mounted () {
            // Если бухгалтер то возвращаем в Выдачу ЗП
            if(this.user && this.user.groupCode == 'B'){
                this.$router.push('/salaries/');
            }
        },
        computed: {
            ...mapState({
                itemTopActive: state => state.menus.itemTopActive,
                itemSecondActive: state => state.menus.itemSecondActive,
                menuSecond: state => state.menus.itemsSecond,
                popups: state => state.popups.items,
                requests: state => state.requests.items,
                isLoad: state => state.requests.isLoad,
                user: state => state.auth.user,
                menuList: state => state.menus.itemsTop,
            }),
            pageCode() {
                if(this.user.groupCode == 'F'){
                    return this.activeTopMenu.id ? 'master'+this.activeTopMenu.id : '';
                }
                return this.itemSecondActive.code;
            },
            activeTopMenu() {
                let code = '';
                let item = {};

                if (this.$route) {
                    code = this.$route.path;
                }

                if (typeof code !== 'undefined') {
                    code = code.split('/')[1];
                }
                for(let m in this.menuList){
                    if(code == 'master'+this.menuList[m].id){
                        item = this.menuList[m]
                    }
                }

                if (!item.id && this.menuList[0]) {
                    item = this.menuList[0];
                }

                return item
            },
            pageTitle() {
                if(this.user.groupCode == 'F'){
                    return this.activeTopMenu.name ? 'Объекты мастера «'+this.activeTopMenu.name.trim()+'»' : '';
                }
                let activeItem = this.itemSecondActive;

                return activeItem.title;
            },
            pageDesc() {
                if(this.user.groupCode == 'F'){
                    return 'Объекты, по которым мастер<br>должен сдать нам деньги';
                }
                let activeItem = this.itemSecondActive;
                return activeItem.description;
            },
            lastTabText() {
                let tabs = {
                    'request_approved': {
                        title: this.itemSecondActive.title,
                        link: '/measure/',
                        toName: 'ЗАМЕРЫ'
                    },
                    'measure_completed': {
                        title: this.itemSecondActive.title,
                        link: '/concepts/',
                        toName: 'КОНЦЕПЦИИ'
                    },
                    'concepts_agreed': {
                        title: this.itemSecondActive.title,
                        link: '/d3/',
                        toName: '3D-МОДЕЛИ'
                    },
                    '3d_agreed': {
                        title: this.itemSecondActive.title,
                        link: '/drawing/',
                        toName: 'ЧЕРТЕЖИ'
                    },
                }

                return tabs[this.itemSecondActive.code];
            }
        },
        methods: {
            fetchData () {

            },
            openHowPressBtn(){
				 this.$store.dispatch('popups/open', {name: this.popups.howPressBtn.name});
			}
        },
        watch: {
            '$route': 'fetchData'
        },
    }
</script>
